import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CartSizesPipe, MethodsPipe } from '@trp/community/shared/ui';
import {
  CommunityProfileWidgetConfig,
  SINGLE_FAMILY_VERIFIABLE_PROPERTIES,
} from '@trp/community/interfaces';
import {
  ContainersPipe,
  CurbsideContaminationPipe,
} from '@trp/community/shared/ui';
import {
  PrivateCommunityInformation,
  SingleFamilyProgram,
} from '@trp/nationaldb/interfaces';
import {
  measurementYear,
  poundsPerHousehold,
} from './community-widget-config.util';

const singleFamilyPrograms = (
  translateService: TranslateService,
  decimalPipe: DecimalPipe,
  containersPipe: ContainersPipe,
  curbsideContaminationPipe: CurbsideContaminationPipe,
  cartSizesPipe: CartSizesPipe,
  methodsPipe: MethodsPipe
): CommunityProfileWidgetConfig['singleFamilyPrograms'] => ({
  section: 'singleFamilyPrograms',
  title: 'widgets.singleFamilyProgram.title',
  maxColumns: 3,
  propertiesToVerify: [...SINGLE_FAMILY_VERIFIABLE_PROPERTIES],
  widgets: [
    {
      section: 'singleFamilyPrograms',
      infoType: 'collectionType',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.singleFamilyProgram.typeOfCollection',
      icon: 'custom-solid:CollectionFormat',
      infoText:
        'requestForm.questions.singleFamily.recyclingCollectionTypeInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) => !!info.collectionType,
      availableResolver: (info: SingleFamilyProgram) => info.collectionType,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noCollectionTypeInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'collectionFrequency',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.singleFamilyProgram.collectionFrequency',
      icon: 'global:SingleFamilyCollectionFrequency',
      infoText: 'requestForm.questions.singleFamily.collectionFrequencyInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        !!info.collectionFrequency,
      availableResolver: (info: SingleFamilyProgram) =>
        info.collectionFrequency,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noFrequencyInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'containerTypes',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],
      infoText: 'requestForm.questions.singleFamily.containerTypesInfo',
      title: 'widgets.singleFamilyProgram.containerTypes',
      icon: 'custom-solid:Container',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.containerTypes || []).length > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        containersPipe.transform(
          info.containerTypes,
          translateService.instant(
            'widgets.singleFamilyProgram.notice.noContainerInfo'
          )
        ),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noContainerInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'cartSizes',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],
      availableValueFormat: 'html',
      title: 'widgets.singleFamilyProgram.cartSizes',
      icon: 'custom-solid:Container',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.cartSizes || []).length > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        cartSizesPipe.transform(info.cartSizes),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noCartSizes'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'participationRatePercentage',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.singleFamilyProgram.participationRate',
      icon: 'custom-solid:RecyclingParticipation',
      infoText: 'requestForm.questions.singleFamily.participationRateInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.participationRatePercentage || 0) > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        decimalPipe.transform(info.participationRatePercentage, '1.0-2') + '%',
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noParticipationRateInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'participationRateHow',
      type: 'standard',
      locations: ['community-profile'],
      availableValueFormat: 'html',
      title: 'widgets.singleFamilyProgram.participationRateMethod',
      icon: 'custom-solid:RecyclingParticipation',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.participationRateHow || []).length > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        methodsPipe.transform(info.participationRateHow),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noParticipationRateMethodInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'householdsServed',
      type: 'standard',
      availableValueFormat: 'html',
      locations: ['community-profile', 'map-profile'],
      title: 'widgets.singleFamilyProgram.householdsServed',
      icon: 'global:HouseholdsServed',
      infoText: 'requestForm.questions.singleFamily.householdsServedInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.householdsServed || 0) > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        decimalPipe.transform(info.householdsServed, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noHouseholdInfo'
        ),
      measurementYear: measurementYear,
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'tonnage',
      type: 'standard',
      availableValueFormat: 'html',
      locations: ['community-profile'],
      title: 'widgets.singleFamilyProgram.recyclingTons',
      icon: 'custom-solid:TonsRecycled',
      infoText: 'requestForm.questions.singleFamily.singleFamilyTonnageInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) => !!info.tonnage,
      availableResolver: (info: SingleFamilyProgram) =>
        decimalPipe.transform(info.tonnage, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noTonsRecyclablesInfo'
        ),
      measurementYear: measurementYear,
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'poundsPerHousehold',
      infoText: 'requestForm.questions.singleFamily.poundsPerHouseholdInfo',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.singleFamilyProgram.poundsPerHousehold',
      icon: 'custom-solid:TonsRecycled',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.poundsPerHousehold || 0) > 0 ||
        (!!info.tonnage && !!info.householdsServed),
      availableResolver: (info: SingleFamilyProgram) =>
        decimalPipe.transform(poundsPerHousehold(info), '1.0-2'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noPoundsPerHouseholdInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'contaminationRatePercentage',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.singleFamilyProgram.contaminationRate',
      icon: 'custom-solid:ContaminationRate',
      infoText:
        'requestForm.questions.singleFamily.contaminationRatePercentageInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.contaminationRatePercentage || 0) > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        decimalPipe.transform(info.contaminationRatePercentage, '1.0-2') + '%',
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noSingleFamilyContaminationRateInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'contaminationRateHow',
      type: 'standard',
      availableValueFormat: 'html',
      locations: ['community-profile'],
      title: 'widgets.singleFamilyProgram.contaminationRateMethods',
      icon: 'custom-solid:ContaminationRate',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.contaminationRateHow || []).length > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        methodsPipe.transform(info.contaminationRateHow),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noSingleFamilyContaminationMethodsInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'contaminationActivities',
      type: 'standard',
      locations: ['community-profile'],
      title: 'widgets.singleFamilyProgram.contaminationActivities',
      icon: 'custom-solid:IncreaseParticipation',
      infoText: 'requestForm.questions.singleFamily.contaminationControlInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.contaminationControls || []).length !== 0,
      availableValueFormat: 'html',
      availableResolver: (info: SingleFamilyProgram) => {
        return curbsideContaminationPipe.transform(info.contaminationControls);
      },
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noContaminationControlInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'setOutRatePercentage',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.singleFamilyProgram.setOutRate',
      icon: 'custom-solid:ReduceContamination',
      infoText: 'requestForm.questions.singleFamily.setOutRatePercentageInfo',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.setOutRatePercentage || 0) > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        decimalPipe.transform(info.setOutRatePercentage, '1.0-2') + '%',
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noSingleFamilySetOutRateInfo'
        ),
    },
    {
      section: 'singleFamilyPrograms',
      infoType: 'setOutRateHow',
      type: 'standard',
      locations: ['community-profile'],
      availableValueFormat: 'html',
      title: 'widgets.singleFamilyProgram.setOutRateMethods',
      icon: 'custom-solid:ReduceContamination',
      ifInfoAvailable: (info: SingleFamilyProgram) =>
        (info.setOutRateHow || []).length > 0,
      availableResolver: (info: SingleFamilyProgram) =>
        methodsPipe.transform(info.setOutRateHow),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.singleFamilyProgram.notice.noSingleFamilySetOutRateMethodsInfo'
        ),
    },
  ],
});

export default singleFamilyPrograms;
