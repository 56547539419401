import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://api.' + window.location.hostname,
  reporting: {
    enabled: true,
    dsn: 'https://3b8da7885971d477c3c5dc6214a8669d@o1325724.ingest.us.sentry.io/4507941628739584',
  },
  contactEmail: 'solutionshub@recyclingpartnership.org',
  buildInfo: {
    buildNumber: '$$$$TRP_BUILD_NUMBER$$$$',
    buildDate: '$$$$TRP_BUILD_DATE$$$$',
  },
};
