import {
  DropoffProgram,
  MultiFamilyProgram,
  PrivateCommunityInformation,
  SingleFamilyProgram,
} from '@trp/nationaldb/interfaces';

export const hasSingleFamily = (info: PrivateCommunityInformation) =>
  (info.singleFamilyPrograms ?? []).length > 0;
export const hasMultiFamily = (info: PrivateCommunityInformation) =>
  (info.multiFamilyPrograms ?? []).length > 0;
export const poundsPerHousehold = (
  info: DropoffProgram | MultiFamilyProgram | SingleFamilyProgram
) => info.poundsPerHousehold ?? (info.tonnage! * 2000) / info.householdsServed!;

export const measurementYear = (
  info: MultiFamilyProgram | SingleFamilyProgram
): string =>
  info.poundsPerHouseholdYear
    ? `in ${
        info.poundsPerHouseholdYearType === 'Other'
          ? ''
          : `${info.poundsPerHouseholdYearType?.toLowerCase()} year `
      }${info.poundsPerHouseholdYear}`
    : 'Year measured not available';
