import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  CommunityProfileWidgetConfig,
  GENERAL_INFORMATION_VERIFIABLE_PROPERTIES,
} from '@trp/community/interfaces';
import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';

const generalInformation = (
  translateService: TranslateService,
  decimalPipe: DecimalPipe
): CommunityProfileWidgetConfig['generalInformation'] => ({
  section: 'generalInformation',
  title: 'widgets.generalInfo.title',
  maxColumns: 2,
  propertiesToVerify: [...GENERAL_INFORMATION_VERIFIABLE_PROPERTIES],
  widgets: [
    {
      section: 'generalInformation',
      infoType: 'hasProgramDropoff',
      isReadonly: true,
      type: 'standard',
      locations: ['map-profile'],
      title: 'widgets.generalInfo.dropoffCollection',
      icon: 'global:DropOffCollection',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        !!info.hasProgramDropoff,
      availableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.availableForResidents'
        ),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.noDropoffRecyclingCollection'
        ),
    },
    {
      section: 'generalInformation',
      infoType: 'hasProgramSingleFamily',
      isReadonly: true,
      type: 'standard',
      locations: ['map-profile'],

      title: 'widgets.generalInfo.singleFamilyCurbsideCollection',
      icon: 'global:SingleFamilyCurbsideCollection',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        !!info.hasProgramSingleFamily,
      availableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.availableForResidents'
        ),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.noSingleFamilyRecyclingCollection'
        ),
    },
    {
      section: 'generalInformation',
      infoType: 'hasProgramMultiFamily',
      isReadonly: true,
      type: 'standard',
      locations: ['map-profile'],

      title: 'widgets.generalInfo.multifamilyOnPropertyCollection',
      icon: 'custom-solid:Multifamily',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        !!info.hasProgramMultiFamily,
      availableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.availableForResidents'
        ),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.noMultiFamilyRecyclingCollection'
        ),
    },
    {
      section: 'generalInformation',
      infoType: 'onlineSearchUrl',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.generalInfo.onlineSearchUrl',
      icon: 'global:search',
      infoText:
        'requestForm.questions.general.onlineToolForAcceptedMaterialsInfo',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        !!info.onlineSearchUrl,
      availableValueFormat: 'html',
      availableResolver: (info: PrivateCommunityInformation) =>
        `<a href="${info.onlineSearchUrl}" class="link" target="_blank">${info.onlineSearchUrl}</a>`,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.noOnlineSearchUrl'
        ),
    },
    {
      section: 'generalInformation',
      infoType: 'guidelinesWebsite',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.generalInfo.guidelinesWebsite',
      icon: 'global:alternate-email',
      infoText: 'requestForm.questions.general.webpageForAcceptedMaterialInfo',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        !!info.guidelinesWebsite,
      availableValueFormat: 'html',
      availableResolver: (info: PrivateCommunityInformation) =>
        `<a href="${info.guidelinesWebsite}" class="link" target="_blank">${info.guidelinesWebsite}</a>`,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.noGuidelinesWebsite'
        ),
    },
    {
      section: 'generalInformation',
      infoType: 'educationAndOutreachFte',
      type: 'standard',
      locations: ['community-profile'],
      title: 'widgets.generalInfo.numberOfFullTimeStaff',
      icon: 'custom-solid:NumberOfFullTimeEquivalentStaff',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        !!info.numberOfFullTimeStaff,
      availableResolver: (info: PrivateCommunityInformation) =>
        decimalPipe.transform(info.numberOfFullTimeStaff, '1.1-1'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.noNumberOfFullTimeStaffInfo'
        ),
    },
    {
      section: 'generalInformation',
      infoType: 'outreachBudgetPerHouseHold',
      type: 'standard',
      locations: ['community-profile'],
      title: 'widgets.generalInfo.outreachBudgetPerHouseHold',
      icon: 'custom-solid:MedianIncom',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        !!info.outreachBudgetPerHouseHold,
      availableResolver: (info: PrivateCommunityInformation) =>
        `$${decimalPipe.transform(info.outreachBudgetPerHouseHold, '1.2-2')}`,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.generalInfo.notice.noAnnualOutreachBudgetInfo'
        ),
    },
  ],
});

export default generalInformation;
