import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  CommunityProfileWidgetConfig,
  MULTI_FAMILY_VERIFIABLE_PROPERTIES,
} from '@trp/community/interfaces';
import {
  AdditionalServicesPipe,
  ContainersPipe,
  MethodsPipe,
} from '@trp/community/shared/ui';
import {
  MultiFamilyProgram,
  PrivateCommunityInformation,
  SingleFamilyProgram,
} from '@trp/nationaldb/interfaces';
import { CommunityWidgetInfoUtilService } from '../services';
import {
  measurementYear,
  poundsPerHousehold,
} from './community-widget-config.util';

const multiFamilyPrograms = (
  translateService: TranslateService,
  decimalPipe: DecimalPipe,
  containersPipe: ContainersPipe,
  utilService: CommunityWidgetInfoUtilService,
  additionalServicePipe: AdditionalServicesPipe,
  methodsPipe: MethodsPipe
): CommunityProfileWidgetConfig['multiFamilyPrograms'] => ({
  section: 'multiFamilyPrograms',
  title: 'widgets.multiFamilyProgram.title',
  maxColumns: 3,
  propertiesToVerify: [...MULTI_FAMILY_VERIFIABLE_PROPERTIES],
  widgets: [
    {
      section: 'multiFamilyPrograms',
      infoType: 'containerTypes',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],
      title: 'widgets.multiFamilyProgram.containerType',
      icon: 'custom-solid:Container',
      infoText: 'requestForm.questions.multiFamily.containerTypesInfo',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        (info.containerTypes || []).length > 0,
      availableResolver: (info: MultiFamilyProgram) =>
        containersPipe.transform(
          info.containerTypes,
          translateService.instant(
            'widgets.multiFamilyProgram.notice.noContainerInfo'
          )
        ),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noContainerInfo'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'collectionType',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.multiFamilyProgram.typeOfCollection',
      icon: 'custom-solid:CollectionFormat',
      infoText: 'requestForm.questions.multiFamily.collectionTypeInfo',
      ifInfoAvailable: (info: MultiFamilyProgram) => !!info.collectionType,
      availableResolver: (info: MultiFamilyProgram) => info.collectionType,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noCollectionTypeInfo'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],
      infoType: 'ordinanceUrl',
      title: 'widgets.multiFamilyProgram.ordinanceLink',
      infoText: 'requestForm.questions.multiFamily.ordinanceLinkInfo',
      icon: 'custom-solid:OpenNew',
      availableValueFormat: 'html',
      ifInfoAvailable: (info: MultiFamilyProgram) => !!info.ordinanceUrl,
      availableResolver: (info: MultiFamilyProgram) =>
        `<a href="${info.ordinanceUrl}" class="link" target="_blank">${info.ordinanceUrl}</a>`,
      unavailableResolver: (info: MultiFamilyProgram) =>
        translateService.instant(
          info.hasOrdinance
            ? 'widgets.multiFamilyProgram.notice.noOrdinanceInfoTrue'
            : 'widgets.multiFamilyProgram.notice.noOrdinanceInfoFalse'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'hasAdditionalServices',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.multiFamilyProgram.additionalMultifamilyServices',
      icon: 'global:AdditionalMultifamilyServices',
      infoText: 'requestForm.questions.multiFamily.additionalServicesInfo',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        utilService.doesMultiFamilyHaveAdditionalServices(info),
      availableResolver: (info: MultiFamilyProgram) =>
        additionalServicePipe.transform(
          info,
          'widgets.multiFamilyProgram.notice.noAdditionalServices'
        ),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noAdditionalServices'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'householdsServed',
      type: 'standard',
      locations: ['community-profile'],
      availableValueFormat: 'html',
      title: 'widgets.multiFamilyProgram.householdsServed',
      icon: 'global:HouseholdsServed',
      infoText: 'requestForm.questions.multiFamily.householdsServedInfo',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        (info.householdsServed || 0) > 0,
      availableResolver: (info: MultiFamilyProgram) =>
        decimalPipe.transform(info.householdsServed, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noHouseholdInfo'
        ),
      measurementYear: measurementYear,
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'tonnage',
      type: 'standard',
      locations: ['community-profile'],
      availableValueFormat: 'html',
      title: 'widgets.multiFamilyProgram.recyclingTons',
      icon: 'custom-solid:TonsRecycled',
      infoText: 'requestForm.questions.multiFamily.multiFamilyTonnageInfo',
      ifInfoAvailable: (info: MultiFamilyProgram) => !!info.tonnage,
      availableResolver: (info: MultiFamilyProgram) =>
        decimalPipe.transform(info.tonnage, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noTonnageInfo'
        ),
      measurementYear: measurementYear,
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'poundsPerHousehold',
      infoText: 'requestForm.questions.multiFamily.poundsPerHouseholdInfo',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.multiFamilyProgram.poundsPerHousehold',
      icon: 'custom-solid:TonsRecycled',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        (info.poundsPerHousehold || 0) > 0 ||
        (!!info.tonnage && !!info.householdsServed),
      availableResolver: (info: MultiFamilyProgram) =>
        decimalPipe.transform(poundsPerHousehold(info), '1.0-2'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noPoundsPerHouseholdInfo'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'contaminationRatePercentage',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.multiFamilyProgram.contaminationRate',
      icon: 'custom-solid:ContaminationRate',
      infoText:
        'requestForm.questions.multiFamily.contaminationRatePercentageInfo',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        (info.contaminationRatePercentage || 0) > 0,
      availableResolver: (info: MultiFamilyProgram) =>
        `${decimalPipe.transform(info.contaminationRatePercentage, '1.0-2')}%`,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noMultiFamilyContaminationRateInfo'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'contaminationRateHow',
      type: 'standard',
      availableValueFormat: 'html',
      locations: ['community-profile'],
      title: 'widgets.multiFamilyProgram.contaminationRateMethods',
      icon: 'custom-solid:ContaminationRate',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        (info.contaminationRateHow || []).length > 0,
      availableResolver: (info: MultiFamilyProgram) =>
        methodsPipe.transform(info.contaminationRateHow),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noMultiFamilyContaminationMethodsInfo'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'participationRatePercentage',
      type: 'standard',
      locations: ['community-profile'],

      title: 'widgets.multiFamilyProgram.participationRate',
      icon: 'custom-solid:RecyclingParticipation',
      infoText: 'requestForm.questions.multiFamily.participationRateInfo',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        (info.participationRatePercentage || 0) > 0,
      availableResolver: (info: MultiFamilyProgram) =>
        `${decimalPipe.transform(info.participationRatePercentage, '1.0-2')}%`,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noParticipationRateInfo'
        ),
    },
    {
      section: 'multiFamilyPrograms',
      infoType: 'participationRateHow',
      type: 'standard',
      locations: ['community-profile'],
      availableValueFormat: 'html',
      title: 'widgets.multiFamilyProgram.participationRateMethod',
      icon: 'custom-solid:RecyclingParticipation',
      ifInfoAvailable: (info: MultiFamilyProgram) =>
        (info.participationRateHow || []).length > 0,
      availableResolver: (info: MultiFamilyProgram) =>
        methodsPipe.transform(info.participationRateHow),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.multiFamilyProgram.notice.noParticipationRateMethodInfo'
        ),
    },
  ],
});

export default multiFamilyPrograms;
