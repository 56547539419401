import {
  DropoffProgram,
  MultiFamilyProgram,
  PrivateCommunityInformation,
  SingleFamilyProgram,
} from '@trp/nationaldb/interfaces';
import { UnionIncludeOnly } from '@trp/shared/util';

export type CommunityProfileWidgetLocation =
  | 'community-profile'
  | 'map-profile';
export type CommunityProfileWidgetType = 'standard';
export type CommunityProfileSections = keyof CommunityProfilePropInfo;
export type ProgramOrSectionType = UnionIncludeOnly<
  CommunityProfileSections,
  | 'generalInformation'
  | 'singleFamilyPrograms'
  | 'multiFamilyPrograms'
  | 'dropoffPrograms'
>;

export const COMMUNITY_PROFILE_SECTIONS: CommunityProfileSections[] = [
  'generalInformation',
  'singleFamilyPrograms',
  'multiFamilyPrograms',
  'dropoffPrograms',
  'programOutputs',
  'programResources',
  'censusData',
];

export const GENERAL_INFORMATION_VERIFIABLE_PROPERTIES: SectionProperties<'generalInformation'>[] =
  [
    'onlineSearchUrl',
    'guidelinesWebsite',
    'totalTonnage',
    'educationAndOutreachFte',
    'outreachBudgetPerHouseHold',
  ];
export const SINGLE_FAMILY_VERIFIABLE_PROPERTIES: SectionProperties<'singleFamilyPrograms'>[] =
  [
    'collectionType',
    'householdsServed',
    'materialsAccepted',
    'collectionFrequency',
    'containerTypes',
    'contaminationControls',
    'contaminationRatePercentage',
    'participationRateHow',
    'participationRatePercentage',
    'poundsPerHousehold',
    'setOutRatePercentage',
    'tonnage',
    'cartSizes',
    'poundsPerHouseholdYear',
    'poundsPerHouseholdYearType',
    'contaminationRateHow',
    'measurementYear',
    'setOutRateHow',
  ];
export const MULTI_FAMILY_VERIFIABLE_PROPERTIES: SectionProperties<'multiFamilyPrograms'>[] =
  [
    'collectionType',
    'hasEducation',
    'hasEnforcement',
    'hasTechnicalSupport',
    'hasOrdinance',
    'householdsServed',
    'materialsAccepted',
    'contaminationControls',
    'contaminationRatePercentage',
    'setOutRatePercentage',
    'tonnage',
    'containerTypes',
    'contaminationRateHow',
    'ordinanceUrl',
    'participationRateHow',
    'participationRatePercentage',
    'poundsPerHousehold',
    'poundsPerHouseholdYear',
    'poundsPerHouseholdYearType',
    'measurementYear',
  ];
export const DROPOFF_VERIFIABLE_PROPERTIES: SectionProperties<'dropoffPrograms'>[] =
  [
    'materialsAccepted',
    'contaminationRatePercentage',
    'tonnage',
    'contaminationRateHow',
    'householdsServed',
    'poundsPerHousehold',
    'poundsPerHouseholdYear',
    'poundsPerHouseholdYearType',
    'measurementYear',
  ];

export type SectionVerifiablePropertyMap = {
  [TKey in ProgramOrSectionType]: SectionProperties<TKey>[];
};

export const sectionVerifiablePropertiesMap: SectionVerifiablePropertyMap = {
  generalInformation: GENERAL_INFORMATION_VERIFIABLE_PROPERTIES,
  singleFamilyPrograms: SINGLE_FAMILY_VERIFIABLE_PROPERTIES,
  multiFamilyPrograms: MULTI_FAMILY_VERIFIABLE_PROPERTIES,
  dropoffPrograms: DROPOFF_VERIFIABLE_PROPERTIES,
};

export type CommunityProfileInfoHost = {
  generalInformation: PrivateCommunityInformation;
  singleFamilyPrograms: SingleFamilyProgram;
  multiFamilyPrograms: MultiFamilyProgram;
  dropoffPrograms: DropoffProgram;
  programOutputs: PrivateCommunityInformation;
  programResources: PrivateCommunityInformation;
  censusData: PrivateCommunityInformation;
};
export type CommunityProfilePropInfo = {
  generalInformation: {
    hasProgramDropoff: 'hasProgramDropoff';
    hasProgramMultiFamily: 'hasProgramMultiFamily';
    hasProgramSingleFamily: 'hasProgramSingleFamily';
    hasMultiFamilyOrdinance: 'hasMultiFamilyOrdinance';

    onlineSearchUrl: 'onlineSearchUrl';
    guidelinesWebsite: 'guidelinesWebsite';
    totalTonnage: 'totalTonnage';

    educationAndOutreachFte: 'educationAndOutreachFte';
    fullTimeEquivalentStaff: 'fullTimeEquivalentStaff';
    outreachBudgetPerHouseHold: 'outreachBudgetPerHouseHold';
    participationRateMethod: 'participationRateMethod';
  };

  singleFamilyPrograms: {
    householdsServed: 'householdsServed';
    collectionType: 'collectionType';
    collectionFrequency: 'collectionFrequency';
    containerTypes: 'containerTypes';
    cartSizes: 'cartSizes';
    tonnage: 'tonnage';
    materialsAccepted: 'materialsAccepted';
    contaminationActivities: 'contaminationActivities';
    poundsPerHousehold: 'poundsPerHousehold';
    contaminationRatePercentage: 'contaminationRatePercentage';
    contaminationRateHow: 'contaminationRateHow';
    contaminationControls: 'contaminationControls';
    setOutRatePercentage: 'setOutRatePercentage';
    setOutRateHow: 'setOutRateHow';
    participationRatePercentage: 'participationRatePercentage';
    participationRateHow: 'participationRateHow';
    poundsPerHouseholdYear: 'poundsPerHouseholdYear';
    poundsPerHouseholdYearType: 'poundsPerHouseholdYearType';
    measurementYear: 'measurementYear';
  };

  multiFamilyPrograms: {
    collectionType: 'collectionType';
    householdsServed: 'householdsServed';
    hasEducation: 'hasEducation';
    hasTechnicalSupport: 'hasTechnicalSupport';
    hasEnforcement: 'hasEnforcement';
    hasOrdinance: 'hasOrdinance';
    tonnage: 'tonnage';
    containerTypes: 'containerTypes';
    poundsPerHousehold: 'poundsPerHousehold';
    contaminationRatePercentage: 'contaminationRatePercentage';
    contaminationControls: 'contaminationControls';
    setOutRatePercentage: 'setOutRatePercentage';
    participationRatePercentage: 'participationRatePercentage';
    participationRateHow: 'participationRateHow';

    hasAdditionalServices: 'hasEducation' &
      'hasTechnicalSupport' &
      'hasEnforcement' &
      'hasOrdinance';
    materialsAccepted: 'materialsAccepted';
    ordinanceUrl: 'ordinanceUrl';
    contaminationRateHow: 'contaminationRateHow';
    poundsPerHouseholdYear: 'poundsPerHouseholdYear';
    poundsPerHouseholdYearType: 'poundsPerHouseholdYearType';
    measurementYear: 'measurementYear';
  };

  dropoffPrograms: {
    tonnage: 'tonnage';
    materialsAccepted: 'materialsAccepted';
    householdsServed: 'householdsServed';
    poundsPerHousehold: 'poundsPerHousehold';
    contaminationRatePercentage: 'contaminationRatePercentage';
    contaminationRateHow: 'contaminationRateHow';
    poundsPerHouseholdYear: 'poundsPerHouseholdYear';
    poundsPerHouseholdYearType: 'poundsPerHouseholdYearType';
    measurementYear: 'measurementYear';
  };

  programOutputs: {
    recyclingTonsTotal: 'totalTonnage';
    recyclingTonsSingleFamily: 'singleFamilyTonnage';
    recyclingTonsMultiFamily: 'multiFamilyTonnage';
    recyclingTonsDropoff: 'dropoffTonnage';
    poundsPerHousehold: 'poundsPerHousehold';
    contaminationRatePercentage: 'contaminationRatePercentage';
    setOutRatePercentage: 'setOutRatePercentage';
    metricTonsCO2AvoidedPerYear: 'metricTonsCO2AvoidedPerYear';
  };

  programResources: {
    curbsideContamination: 'curbsideContamination';
    outreachBudgetPerHouseHold: 'outreachBudgetPerHouseHold';
    contaminationControls: 'contaminationControls';
    numberOfFullTimeStaff: 'numberOfFullTimeStaff';
  };

  censusData: {
    totalPopulation: 'totalPopulation';
    occupancyRate: 'occupancyRate';
    percentRaceBipoc: 'percentRaceBipoc';
    percentBachelorsOrHigher: 'percentBachelorsOrHigher';
    incomeMean: 'incomeMean';
  };
};
export type SectionProperties<TSection extends keyof CommunityProfilePropInfo> =
  CommunityProfilePropInfo[TSection][keyof CommunityProfilePropInfo[TSection]] extends string
    ? CommunityProfilePropInfo[TSection][keyof CommunityProfilePropInfo[TSection]]
    : never;

export type GeneralInfoProperties = SectionProperties<'generalInformation'>;
export type SingleFamilyProperties = SectionProperties<'singleFamilyPrograms'>;
export type MultiFamilyProperties = SectionProperties<'multiFamilyPrograms'>;
export type DropoffProperties = SectionProperties<'dropoffPrograms'>;
export type ProgramOutputsProperties = SectionProperties<'programOutputs'>;
export type ProgramResourcesProperties = SectionProperties<'programResources'>;
export type CensusDataProperties = SectionProperties<'censusData'>;

export type PropertyOfSection<
  TSection extends CommunityProfileSections | unknown = unknown
> = TSection extends 'generalInformation'
  ? GeneralInfoProperties
  : TSection extends 'singleFamilyPrograms'
  ? SingleFamilyProperties
  : TSection extends 'multiFamilyPrograms'
  ? MultiFamilyProperties
  : TSection extends 'dropoffPrograms'
  ? DropoffProperties
  : TSection extends 'programOutputs'
  ? ProgramOutputsProperties
  : TSection extends 'programResources'
  ? ProgramResourcesProperties
  : TSection extends 'censusData'
  ? CensusDataProperties
  :
      | GeneralInfoProperties
      | SingleFamilyProperties
      | MultiFamilyProperties
      | DropoffProperties
      | ProgramOutputsProperties
      | ProgramResourcesProperties
      | CensusDataProperties;

export type VerificationPropertyOfSection<
  TSection extends ProgramOrSectionType | unknown = unknown
> = TSection extends 'generalInformation'
  ? GeneralInfoProperties
  : TSection extends 'singleFamilyPrograms'
  ? SingleFamilyProperties
  : TSection extends 'multiFamilyPrograms'
  ? MultiFamilyProperties
  : TSection extends 'dropoffPrograms'
  ? DropoffProperties
  :
      | GeneralInfoProperties
      | SingleFamilyProperties
      | MultiFamilyProperties
      | DropoffProperties;

export type CommunityProfileWidgetDefinition<
  TSection extends CommunityProfileSections
> = {
  section: TSection;
  type: CommunityProfileWidgetType;
  locations: CommunityProfileWidgetLocation[];
  infoType: keyof CommunityProfilePropInfo[TSection];

  title: string;
  icon: string;
  infoText?: string;
  ifInfoAvailable: (
    info: CommunityProfileInfoHost[TSection],
    communityInfo?: PrivateCommunityInformation
  ) => boolean;
  availableValueFormat?: 'any' | 'html';
  availableResolver: (
    info: CommunityProfileInfoHost[TSection],
    communityInfo?: PrivateCommunityInformation
  ) => any;
  unavailableResolver: (
    info: CommunityProfileInfoHost[TSection],
    communityInfo?: PrivateCommunityInformation
  ) => any;
  isReadonly?: boolean;
  measurementYear?: (info: CommunityProfileInfoHost[TSection]) => any;
};

export type CommunityProfileWidgetDefinitionList =
  CommunityProfileWidgetDefinition<any>[];

export type SectionsConfig<TKey extends CommunityProfileSections> = {
  section: TKey;
  title: string;
  maxColumns: number;
  propertiesToVerify: SectionProperties<TKey>[];
  widgets: CommunityProfileWidgetDefinition<TKey>[];
};

export type CommunityProfileWidgetConfig = {
  [TKey in CommunityProfileSections]?: SectionsConfig<TKey>;
};
